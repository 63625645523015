<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card>
          <v-card-title class="elevation-1">
            Edit Product
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="profileForm" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="Rules"
                      v-model="productname"
                      label="Product Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="Rules"
                      v-model="productcost"
                      label="Product Cost"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      dense
                      multiple
                      v-model="category"
                      class="mt-5"
                      label="Category"
                      :items="categoryArray"
                      item-text="name"
                      item-value="_id"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      dense
                      class="mt-5"
                      label="Sub-category"
                      v-model="user.subcategory"
                      :items="subcategoryArray"
                      item-text="name"
                      item-value="_id"
                      v-on:input="subSize(user.subcategory)"
                    >
                      <template v-slot:item="{ item }">
                        <span class="text-left">
                          {{ item.name }}
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="Rules"
                      v-model="user.stockquantity"
                      label="Stock Quantity"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="Rules"
                      v-model="profitmargin"
                      label="Profit Margin"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="Rules"
                      v-model="caption"
                      label="Caption"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="8">
                    <v-select
                      multiple
                      dense
                      color="#FF1313"
                      chips
                      label="Pincode"
                      deletable-chips
                      v-model="pincode"
                      :items="pincodeArray"
                      item-text="Pincode"
                      item-value="Pincode"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-flex xs12 md12 lg10 xl7>
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left>
                      <span class="styloopinputlabel">Product Stock</span>
                    </v-flex>
                    <v-flex xs12>
                      <StockAdder
                        v-bind:storage="subSizeArray"
                        v-bind:sizeData="user.size"
                        :key="subSizeArray[0]"
                        @stepper="winStepper"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex text-left pl-2 xs12 md12 lg12>
                  <span
                    style="
                      padding-left: 10px;
                      text-align: left;
                      letter-spacing: 0.54px;
                      font-size: 12px;
                    "
                    >Add Description</span
                  >
                  <!-- <v-chip-group> -->
                  <v-layout wrap justify-space-between>
                    <v-flex v-for="(tagItem, i) in tagArray" :key="i" pa-2>
                      <v-chip close @click:close="removeTag(i)"
                        >{{ tagItem }}
                      </v-chip>
                    </v-flex>
                    <!-- <v-layout wrap justify-space-between> -->
                    <v-flex pa-2>
                      <v-dialog
                        v-model="tagDialog"
                        max-width="500px"
                        transition="dialog-transition"
                      >
                        <template v-slot:activator="{ on }">
                          <v-chip
                            v-on="on"
                            style="cursor: pointer"
                            color="red"
                            outlined
                          >
                            <span style="color: #000000">
                              <v-icon>mdi-plus</v-icon> Edit Tag
                            </span>
                          </v-chip>
                        </template>
                        <v-card class="pa-2">
                          <v-layout wrap>
                            <v-flex xs12 text-left>
                              <span
                                style="
                                  padding-left: 10px;
                                  text-align: left;
                                  letter-spacing: 0.54px;
                                  font-size: 13px;
                                "
                                >Add Description</span
                              >
                            </v-flex>
                            <v-flex xs12>
                              <v-text-field
                                v-model="tag"
                                dense
                                outlined
                                placeholder="Add Description"
                                color="#FF1313"
                              >
                              </v-text-field>
                            </v-flex>
                          </v-layout>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="tagDialog = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="addTag()">
                              Add
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-flex>
                    <!-- </v-layout> -->
                  </v-layout>
                  <!-- </v-chip-group> -->
                </v-flex>
                <v-layout wrap>
                  <v-flex md3 lg3 pl-3 text-left>
                    <v-card pr-4flat tile outlined>
                      <v-layout wrap>
                        <!-- <v-flex md4 lg4>
                          <v-img
                            :src="baseURL + user.photos[0]"
                            height="113px"
                            contain
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"  
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="#FF0000"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-flex> -->
                        <v-flex pl-2 md8 lg8>
                          <v-layout wrap>
                            <v-flex
                              pt-2
                              md6
                              v-for="(item, i) in user.photos"
                              :key="i"
                              px-2
                              class="hidden-sm-and-down"
                            >
                              <v-badge small overlap avatar color="#FF1313">
                                <template v-slot:badge>
                                  <v-avatar>
                                    <v-icon small @click="removePhotos(i)"
                                      >mdi-close</v-icon
                                    >
                                  </v-avatar>
                                </template>
                                <v-img
                                  v-if="item"
                                  :src="baseURL + item"
                                  height="44px"
                                  width="44px"
                                  contain
                                >
                                </v-img>
                              </v-badge>
                            </v-flex>
                          </v-layout>
                          <v-layout wrap>
                            <v-flex
                              lg6
                              md6
                              v-for="(item, i) in imageArray"
                              :key="i"
                              px-2
                              class="hidden-sm-and-down"
                            >
                              <v-badge small overlap avatar color="#FF1313">
                                <template v-slot:badge>
                                  <v-avatar>
                                    <v-icon small @click="removeImageArray(i)"
                                      >mdi-close</v-icon
                                    >
                                  </v-avatar>
                                </template>
                                <v-img :src="item" height="44px" width="44px">
                                </v-img>
                              </v-badge>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                    <br />
                    <span>
                      <v-btn
                        small
                        :ripple="false"
                        id="pro_pic"
                        class="text-capitalize"
                        color="#EFEFEF "
                        width="80%"
                        @click="$refs.files.click()"
                      >
                        <span style="font-size: 12px">Upload Image</span>
                      </v-btn>
                      <input
                        v-show="false"
                        accept="image/*"
                        id="file"
                        ref="files"
                        multiple="multiple"
                        type="file"
                        @change="uploadImages"
                      />
                    </span>
                  </v-flex>
                </v-layout>
                <v-row class="justify-center">
                  <v-btn
                    :disabled="!valid"
                    outlined
                    color="red"
                    @click="validate()"
                  >
                    Update
                  </v-btn>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import StockAdder from "./stockAddrer";
import axios from "axios";
export default {
  components: {
    StockAdder,
  },
  props: ["productid"],
  data() {
    return {
      deleteImageArrayDialog: false,
      imageArray: [],
      Images: new FormData(),
      formData: new FormData(),
      delFormData: new FormData(),
      selectedFiles: null,
      i: null,
      ServerError: false,
      caption: "",
      file: null,
      image: "",
      tagDialog: false,
      sizeDialog: false,
      appLoading: false,
      showsnackbar: false,
      productname: null,
      productcost: null,
      stockquantity: null,
      category: [],
      pincodeArray: [],
      sizeCatArray: [],
      profitmargin: "",
      tag: "",
      tagArray: [],
      tags: [],
      pincode: [],
      categoryArray: [],
      subcategoryArray: [],
      subcategory: null,
      subSizeArray: [],
      // addSize: "",
      // addStock: "",
      genSize: [],
      genStock: [],
      productStock: {},
      msg: null,
      valid: true,
      user: {
        stockquantity: null,
        instock: false,
        size: [],
      },

      Rules: [(v) => !!v || "Required"],
    };
  },
  mounted() {
    this.getData();
    this.getCategory();
    this.getPincode();
    this.getSubcategory();
    this.generateSizeStock();
  },
  computed: {
    inStock() {
      if (this.user.stockquantity > 0) {
        return true;
      } else if (this.user.stockquantity == null) return null;
      else return false;
    },
  },
  methods: {
    winStepper(window_data) {
      this.productStock = window_data.productStock;
      this.user.size = this.productStock;
      this.user.stockquantity = 0;
      for (var i = 0; i < this.productStock.length; i++) {
        for (var j = 0; j < this.productStock[i].stock.length; j++) {
          this.user.stockquantity =
            this.user.stockquantity + this.productStock[i].stock[j].stock;
        }
      }
    },
    validate() {
      if (this.$refs.profileForm.validate()) {
        this.editUser();
      }
    },
    subSize(id) {
      for (var i = 0; i < this.subcategoryArray.length; i++) {
        if (id == this.subcategoryArray[i]._id) {
          this.subSizeArray = this.subcategoryArray[i].size;
        }
      }
    },
    // addSizes() {
    //   this.sizeCatArray.push({ size: this.addSize, stock: this.addStock });
    //   this.stockquantity = this.stockquantity + this.addStock;
    //   this.addSize = "";
    //   this.addStock = "";
    // },
    removeSize(i) {
      this.stockquantity = this.stockquantity - this.sizeCatArray[i].stock;
      this.sizeCatArray.splice(i, 1);
    },
    addTag() {
      this.tagArray.push(this.tag);
      this.tag = "";
    },
    removeTag(i) {
      this.tagArray.splice(i, 1);
    },
    getCategory() {
      this.appLoading = true;
      axios({
        url: "/category/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.categoryArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getSubcategory() {
      this.appLoading = true;
      axios({
        url: "/subcategory/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.subcategoryArray = response.data.data;
        })
        .catch((err) => {
          this.ServerError = true;
          this.appLoading = false;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/product/info",
        method: "GET",
        params: {
          id: this.productid,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.productname = this.user.productname;
          this.productcost = this.user.productcost;
          this.profitmargin = this.user.profitmargin;
          this.stockquantity = this.user.stockquantity;
          this.caption = this.user.caption;
          this.subSize(this.user.subcategory._id);

          for (var i = 0; i < this.user.category.length; i++) {
            this.category.push(this.user.category[i]._id);
          }
          this.subcategory = this.user.subcategory._id;
          for (var j = 0; j < this.user.pincode.length; j++) {
            var num = this.user.pincode[j].toString();
            this.pincode.push(num);
          }
          for (var k = 0; k < this.user.tags.length; k++) {
            this.tagArray.push(this.user.tags[k]);
          }
          for (var l = 0; l < this.user.size.length; l++) {
            this.sizeCatArray.push(this.user.size[l]);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    generateSizeStock() {
      for (var i = 5; i <= 60; i++) {
        this.genSize.push(i);
      }
      for (var j = 0; j <= 1000; j++) {
        this.genStock.push(j);
      }
    },
    getPincode() {
      this.appLoading = true;
      axios({
        url: "/loc/pincodelist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.pincodeArray = response.data.data;
        })
        .catch((err) => {
          this.ServerError = true;
          this.appLoading = false;
          console.log(err);
        });
    },
    removePhotos(i) {
      var Data = {};
      this.user.photos.splice(i, 1);
      Data["productid"] = this.productid;
      Data["sellerid"] = this.user.seller._id;
      Data["position"] = i + 1;
      axios({
        method: "POST",
        url: "/Product/RemoveImagesingle",
        data: Data,
        headers: {
          // "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.showSnackBar = true;
            this.msg = "Deleted Successfully";
            this.$emit("stepper", {
              editProduct: true,
              getList: true,
            });
            // this.$router.go();
          } else {
            this.msg = "Can't Delete Image";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    removeImageArray(i) {
      var values = this.formData.getAll("photos");
      values.splice(i, 1);
      this.formData.set("photos", values);
      console.log(this.formData);
      this.imageArray.splice(i, 1);
      this.deleteImageArrayDialog = false;
    },
    uploadImages(event) {
      if (this.imageArray.length < 4) {
        this.selectedFiles = event.target.files[0];
        this.Images.append("image", this.selectedFiles);
        this.formData.append("photos", this.selectedFiles);
        console.log("files", this.Images);
        var img;
        img = new Image();
        img.src = window.URL.createObjectURL(event.target.files[0]);
        img.onload = () => {
          const urls = URL.createObjectURL(this.selectedFiles);
          if (this.user.photos.length + this.imageArray.length < 4) {
            this.imageArray.push(urls);
          } else {
            this.msg = "Maximum image limit exceeded!";
            this.showSnackBar = true;
          }
          if (this.imageArray) {
            this.image = this.imageArray[0];
          }
        };
      } else {
        this.msg = "Maximum image limit exceeded!";
        this.showSnackBar = true;
        return;
      }
    },
    uploadEditedImages() {
      console.log(this.product);
      this.formData.append("productid", this.productid);
      this.formData.append("sellerid", this.user.seller._id);
      axios({
        method: "POST",
        url: "/Product/UploadImageMultiple",
        data: this.formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.$emit("stepper", {
              getList: true,
            });
            this.$router.go(-1);
          } else {
            this.msg = "Can't Upload Images";
            this.showsnackbar = true;
            this.$router.go(-1);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editUser() {
      this.appLoading = true;
      var user = {};
      user["productname"] = this.productname;
      user["productcost"] = this.productcost;
      user["profitmargin"] = this.profitmargin;
      user["subcategory"] = this.user.subcategory;
      user["category"] = this.category;
      user["pincode"] = this.pincode;
      user["tags"] = this.tagArray;
      user["size"] = this.productStock;
      user["caption"] = this.caption;
      user["id"] = this.productid;
      this.user.stockquantity = Number(this.user.stockquantity);
      if (this.user.stockquantity > 0) this.user.instock = true;
      axios({
        method: "POST",
        url: "/Product/Edit",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: user,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.uploadEditedImages();
            this.$emit("stepper", {
              editProduct: false,
              getList: true,
            });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.$emit("stepper", {
              getList: true,
            });
            this.$router.go(-1);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>